import styles from "./RandomGameModal.module.css";

export default function RandomGameModal({children,  onClose}) {
    return (
        <div className={styles.modalBackdrop} onClick={() => onClose()}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <button className={styles.closeButton} onClick={() => onClose()}>
                    &times;
                </button>
                <div className={styles.modalBody}>
                    {children}
                </div>
            </div>
        </div>
    );
}