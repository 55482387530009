import { useEffect, useRef } from "react";

const useWebSocket = (wsUrl, gameRoom, onRedirect) => {
    const socketRef = useRef(null);

    useEffect(() => {
        if (gameRoom !== null) {
            if (socketRef.current) {
                socketRef.current.close();
            }

            socketRef.current = new WebSocket(`${wsUrl}${gameRoom.id}/`)
            socketRef.current.onmessage = (e) => {
                const data = JSON.parse(e.data);
                onRedirect(data);
            };

            socketRef.current.onopen = () => {
                socketRef.current.send(JSON.stringify({ room: gameRoom }))
            };
            socketRef.current.onerror = (error) => {
                console.error("WebSocket Error: ", error);
            };
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, [wsUrl, gameRoom, onRedirect]);
};

export default useWebSocket;