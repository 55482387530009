import styles from "./PlayerDashboard.module.css";
import React, { useState  } from "react";
import useWebSocket from "../../../hooks/socket";
import GameAction from "../GameAction/GameAction";
import {GAME_ROOM_WS_URL} from "../../../constants/main/sockets";
import PlayerProfileSummary from "../PlayerProfile/PlayerProfileSummary";
export default function PlayerDashboard() {
    const [gameRoom, setGameRoom] = useState(null);
    const wsGameRoomUrl = `${GAME_ROOM_WS_URL}`
    const handleRandomGameRoomUpdate = (newGameRoom) => {
        setGameRoom(newGameRoom);
    };
    const handleRedirectToGame = (data) => {
        if (data.room['second_player'] !== null) {
            console.log("redirect to game page");
        }
    };

    useWebSocket(wsGameRoomUrl, gameRoom, handleRedirectToGame);

    return (
        <div className={styles.player_dashboard_card}>
            <PlayerProfileSummary></PlayerProfileSummary>
            <GameAction onDataAction={handleRandomGameRoomUpdate}></GameAction>
        </div>
    );
}
