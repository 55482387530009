import styles from "./PlayerProfileSummary.module.css";
import React, {useEffect, useState} from "react";
import defaultAvatar from "../../../static/profile_default.png";
import {getProfileSummaryFields} from "../../../api/main";
import AvatarProfile from "../AvatarProfile/AvatarProfile";

export default function PlayerProfileSummary(){
    const [profile, setProfile] = useState({
        username: "isLoading",
        rating: "isLoading",
        avatar: defaultAvatar,
    });

    useEffect(() => {
        const accessToken = localStorage.getItem("access");
        if (accessToken) {
            getProfileSummaryFields(accessToken)
                .then((response) => {
                    const data = response.data;
                    setProfile({
                        username: data.username || profile.username,
                        rating: data.rating || profile.rating,
                        avatar: data.photo || defaultAvatar,
                    });
                })
                .catch((error) => {
                    console.error("Error fetching profile:", error);
                });
        }
    }, [profile.rating, profile.username]);

    return (
        <>
            <div className={styles.photo_management}>
                <AvatarProfile avatar={profile.avatar}></AvatarProfile>
            </div>
            <div className={styles.profile_management}>
                <div className={styles.profile_field}>{profile.username}</div>
                <div className={styles.profile_field}>Rating: {profile.rating}</div>
            </div>
        </>
    )
}