import axios from "axios";
import {
    PATCH_AVATAR_API_URL,
    GET_PROFILE_FIELDS_API_URL,
    POST_FRIEND_API_URL,
    PATCH_ACCEPT_FRIENDSHIP_API_URL, DELETE_DENIED_FRIENDSHIP_API_URL, GET_FRIENDSHIP_INVITES_API_URL
} from "../constants/profile/api";
import {getAuthConfig} from "../utils/general/general";

export const patchAvatar = (avatarFile, token) => {
    const formData = new FormData();
    formData.append('photo', avatarFile);

    return axios.patch(PATCH_AVATAR_API_URL, formData, getAuthConfig(token));
}

export const getProfileFields = (token) => {
    return axios.get(GET_PROFILE_FIELDS_API_URL, getAuthConfig(token))
}

export const addFriend = (data, token) => {
    return axios.post(POST_FRIEND_API_URL, data, getAuthConfig(token))
}

export const acceptFriendship = (data, token) => {
    return axios.patch(PATCH_ACCEPT_FRIENDSHIP_API_URL, data, getAuthConfig(token))
}

export const deniedFriendship = (inviter, token) => {
    const url = `${DELETE_DENIED_FRIENDSHIP_API_URL}${inviter}/`
    return axios.delete(url, getAuthConfig(token))
}

export const getFriendshipInvites = (token) => {
    return axios.get(GET_FRIENDSHIP_INVITES_API_URL, getAuthConfig(token))
}
