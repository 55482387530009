import styles from "./GameAction.module.css"
import React from "react";
import {createGameRoom} from "../../../api/main";
import {useModal} from "../../../hooks/SearchRandomModalContext";
export default function GameAction({ onDataAction }){
    const { openModal, updateTextModal } = useModal();
    const handleRandomGame = () => {
        const token = localStorage.getItem("access");
        createGameRoom(token)
            .then((response) => {
                onDataAction(response.data)
            })
            .catch(() => {
                updateTextModal()
            });
        openModal()
    };

    return (
        <div className={styles.game_actions}>
            <div className={styles.game_action_btn} onClick={handleRandomGame}>
                Random game
            </div>
            <div className={styles.game_action_btn}>VS Computer</div>
        </div>
    )
}