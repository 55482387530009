import styles from "../../assets/Main.module.css";
import React from 'react';
import LogoutPanel from "../../components/MainComponents/Logout/LogoutPanel";
import PlayerDashboard from "../../components/MainComponents/PlayerDashboard/PlayerDashboard";
import FriendLeaderStatistic from "../../components/MainComponents/Statistics/FriendLeaderStatistic";
import CommonLeaderStatistic from "../../components/MainComponents/Statistics/CommonLeaderStatistic";
import {useModal} from "../../hooks/SearchRandomModalContext";
import RandomGameModal from "../../components/MainComponents/RandomGameModal/RandomGameModal";
import {deleteGameRoom} from "../../api/main";
export default function Main() {
    const { textModal, succeedText, isModalOpen, closeModal} = useModal();
    function deleteGameRoomRequest(){
        const token = localStorage.getItem("access")
        deleteGameRoom(token)
            .then(() => {
                closeModal()
            })
    }
    return (
        <div className={styles.main_page}>
            <LogoutPanel></LogoutPanel>
            <PlayerDashboard></PlayerDashboard>

            <div className={styles.statistics}>
                <FriendLeaderStatistic></FriendLeaderStatistic>
                <CommonLeaderStatistic></CommonLeaderStatistic>
            </div>
            {isModalOpen &&
                <RandomGameModal onClose={
                    () => closeModal(succeedText === textModal ? deleteGameRoomRequest : null)}>{textModal}
                </RandomGameModal>}
        </div>
    );
}
