import LogoutPanel from "../../components/MainComponents/Logout/LogoutPanel";
import React from "react";
import styles from "../../assets/Profile.module.css"
import PersonalPlayerDashboard from "../../components/ProfileComponents/ProfileInformation/PersonalPlayerDashboard";
import GameStatistic from "../../components/ProfileComponents/GameStatistic/GameStatistic";
import {useModal} from "../../hooks/SearchRandomModalContext";
import AddFriendModal from "../../components/ProfileComponents/AddFriendModal/AddFriendModal";
import FriendshipInvitation from "../../components/ProfileComponents/FriendshipInvitation/FriendshipInvitation";
export default function Profile(){
    const {isModalOpen, closeModal} = useModal();

    return (
        <div className={styles.profile_page}>
            <LogoutPanel></LogoutPanel>

            <div className={styles.player_overview}>
                <PersonalPlayerDashboard></PersonalPlayerDashboard>
                <div className={styles.player_content}>
                    <GameStatistic></GameStatistic>
                    <FriendshipInvitation></FriendshipInvitation>
                </div>

            </div>
            {isModalOpen && <AddFriendModal onClose={() => closeModal()}></AddFriendModal>}
        </div>
    )
}