export function getPartsUrlElements(){
    const url= window.location.href
    const parts = url.split('/')
    const uid = parts[parts.length - 3]
    const token = parts[parts.length - 2]
    return [uid, token]
}

export const getAuthConfig = (token) => ({
    headers: {
        Authorization: `Bearer ${token}`,
    },
})