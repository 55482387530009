import styles from "./Statistics.module.css";
import React, {useEffect, useState} from "react";
import {getFriendLeaders} from "../../../api/main";

export default function FriendLeaderStatistic(){
    const [friendLeaders, setFriendLeaders] = useState([])
    const trList = friendLeaders.map((friendData) =>
        <tr key={friendData.username}>
            <td>{friendData.username}</td>
            <td>{friendData.rating}</td>
            <td><div className={styles.play_btn}>play</div></td>
        </tr>
    )
    useEffect(() => {
        const token = localStorage.getItem("access")
        getFriendLeaders(token)
            .then((response) => {
                setFriendLeaders(response.data)
            })
    }, []);

    return (
         <div className={styles.friends_statistics}>
              <table className={styles.game_statistics_table}>
                  <thead>
                      <tr>
                          <th>Friend username</th>
                          <th>Points</th>
                          <th>Play with friend</th>
                      </tr>
                  </thead>
                  <tbody>
                      {trList}
                  </tbody>
              </table>
         </div>
    )
}