import axios from "axios";
import {getAuthConfig} from "../utils/general/general";
import {DELETE_GAME_ROOM_API_URL, GAME_ROOM_API_URL, GET_FRIEND_LEADERS_API_URL} from "../constants/main/api";
import {GET_LEADERS_API_URL, GET_PROFILE_SUMMARY_FIELDS_API_URL} from "../constants/main/api";

export const createGameRoom = (token) => {
    return axios.post(GAME_ROOM_API_URL, {}, getAuthConfig(token))
}

export const deleteGameRoom = (token) => {
    return axios.delete(DELETE_GAME_ROOM_API_URL, getAuthConfig(token))
}

export const getProfileSummaryFields = (token) => {
    return axios.get(GET_PROFILE_SUMMARY_FIELDS_API_URL, getAuthConfig(token));
}

export const getLeaders = (token) => {
    return axios.get(GET_LEADERS_API_URL, getAuthConfig(token))
}

export const getFriendLeaders = (token) => {
    return axios.get(GET_FRIEND_LEADERS_API_URL, getAuthConfig(token))
}