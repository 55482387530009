import styles from "../PlayerProfile/PlayerProfileSummary.module.css";
import React from "react";
import {patchAvatar} from "../../../api/profile";
import RouteEnum from "../../../constants/Enums/EnumRoutes";
import {useNavigate} from "react-router-dom";

export default function AvatarProfile({avatar, updateAvatar=null}){
    const fileInputRef = React.useRef(null);
    const navigate = useNavigate();

    function addNewAvatar(avatarFile){
        const token = localStorage.getItem('access')

        patchAvatar(avatarFile, token)
            .then((response) => {
                if(updateAvatar){
                    updateAvatar(response.data.photo)
                }
            })
    }
    const handleAvatarClick = () => {
        if(updateAvatar){
            fileInputRef.current.click();
        }else{
            navigate(RouteEnum.PROFILE);
        }
    };
    const handleFileChange = (event) => {
        const files = event.target.files;

        if (files.length > 0) {
            const selectedFile = files[0];
            addNewAvatar(selectedFile)
        }
    };
    return (
        <div className={styles.profile_photo}>
            <img className={styles.photo_img} src={avatar} onClick={handleAvatarClick} alt="Profile" />
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
            />
        </div>
    )
}