import React, { useState } from "react";
import styles from "./AddFriendModal.module.css";
import {addFriend} from "../../../api/profile";

export default function AddFriendModal({ onClose}) {
    const [friendName, setFriendName] = useState("");
    const [apiError, setApiError] = useState()
    const handleInputChange = (e) => {
        setFriendName(e.target.value);
    };

    const handleSendFriendshipRequest = () => {
        if (friendName.length !== 0) {
            const data = {"recipient": friendName}
            const token = localStorage.getItem('access')
            addFriend(data, token)
                .then(() => {
                    onClose()
                })
                .catch((error) => {
                    const errorResponse = error.response.data.detail
                    setApiError(errorResponse)
                })
            setFriendName("");
        }
    };

    return (
        <div className={styles.modalBackdrop} onClick={() => onClose()}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <button className={styles.closeButton} onClick={() => onClose()}>
                    &times;
                </button>
                <div className={styles.modalBody}>
                    <input
                        type="text"
                        className={styles.inputField}
                        placeholder="Enter friend's name"
                        value={friendName}
                        onChange={handleInputChange}
                    />
                    <div className={styles.errorMessage}>{apiError}</div>
                    <button className={styles.submitButton} onClick={handleSendFriendshipRequest}>
                        Send Friend Request
                    </button>
                </div>
            </div>
        </div>
    );
}
