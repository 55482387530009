import styles from "./Statistics.module.css";
import React, {useEffect, useState} from "react";
import {getLeaders} from "../../../api/main";

export default function CommonLeaderStatistic(){
    const [leaders, setLeaders] = useState([])

    const trLeaders = leaders.map((leaderData) =>
        <tr key={leaderData.username}>
            <td>{leaderData.username}</td>
            <td>{leaderData.rating}</td>
        </tr>
    )

    useEffect(() => {
        const token = localStorage.getItem('access')
        getLeaders(token)
            .then((response) => {
                setLeaders(response.data)
            })
    }, []);


    return (
        <div className={styles.leaders_statistics}>
            <table className={styles.game_statistics_table}>
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Points</th>
                    </tr>
                </thead>
                <tbody>
                    {trLeaders}
                </tbody>
            </table>
        </div>
    )
}